import numeral from 'numeral';

export default function useMoney() {
    function formatMoney(amount, separator, currency = null, currencyPosition = 'after') {
        let formattedValue = numeral(amount)
            .format('00')
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, separator);

        if (currency === null) {
            return formattedValue;
        }

        return withCurrency(formattedValue, currency, currencyPosition);
    }

    function withCurrency(value, currency, currencyPosition = 'after') {
        if (currencyPosition === 'before') {
            return currency + ' ' + value;
        }

        return value + ' ' + currency;
    }

    function formatCurrencyConverted(amount, separator, originalCurrency, targetCurrency, exchangeRate) {
        let originalFormattedAmount = formatMoney(amount, separator, originalCurrency);

        let convertedAmount = convertCurrency(amount, exchangeRate);
        let convertedFormattedAmount = formatMoney(convertedAmount, separator, targetCurrency);

        return `${originalFormattedAmount} (${convertedFormattedAmount})`;
    }

    function convertCurrency(amount, exchangeRate) {
        return amount * exchangeRate;
    }

    return {
        formatMoney,
        formatCurrencyConverted,
        convertCurrency,
    };
}
